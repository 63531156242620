export default {
    header: {
        home: "ホーム",
        about: "ニュース",
        news: "最新情報",
        products: "製品",
        om: "運用",
        cases: "ケース",
        contact: "連絡"
    },
	footer: {
        about: "会社概要",
        intro: "企業紹介",
        course: "発展历程",
		honor: "荣誉资质",
        maintenance: "運用サービス",
        smart: "スマートO&M",
        install: "太陽光発電システム設置",
        industry: "産業オートメーション",
        service: "製品サービス",
        download: "ダウンロード",
		video: "ビデオ",
		products: "おすすめ製品",
		rdn12: "スマート清掃ロボット",
		rdn21: "スマート乾式清掃ロボット",
		rdn22: "遠隔操作清掃ロボット",
		rdn31: "ハンディ清掃ツール",
		rdn51: "スマート観測ステーション",
		rdn71: "スマート軌道清掃ロボット",
        sweep: "スキャン",
        shop: "フォローする オンラインストア",
        contact: "お問い合わせ",
        address: "住所：中国江蘇省蘇州市胥口镇子胥路636号",
        copyright: "Copyright © 2019 radiantpv.com 苏州瑞得恩光能科技有限公司版权所有;部分信息来自互联网,如有侵权,请告知! ",
        record: "蘇ICP备14042181号-1"
    },
	home: {
        bannerH1: "太陽光発電所用スマートレール式清掃ロボット",
        bannerH2: "太陽光発電所用スマート清掃ロボット",
        bannerH3: "太陽光発電所用高効率手持ち清掃機",
        bannerP1: "スマート運営サービス/運営ロボット/無人監視",
        bannerP2: "スマート運営サービス/運営ロボット/無人監視",
        bannerP3: "スマート運営サービス/運営ロボット/無人監視",
        mainH1: "太陽光発電所のワンストップ全天候",
        mainH2: "スマート運営サービスプロバイダー",
        mainP1: "スマート運営サービス/運営ロボット/太陽光発電システム/産業自動化",
        about: "会社概要",
        aboutP1: "瑞得恩グループは中国蘇州に位置し、太陽光発電所のスマート運営、太陽光発電、太陽光応用製品、アクセサリー、機器、金属製造、精密加工を一体化した総合企業です。",
        aboutP2: "誠実、革新、仕事愛、平和、共栄をコアバリューとし、業界トップの技術でお客様に最も価値のある製品と技術サポートを提供します。",
        omH1: "運営サービス",
        omH2: "太陽光発電所のワンストップ全天候スマート運営サービスプロバイダー",
        omH3: "スマート運営サービス/太陽光発電システム/産業自動化",
        omP1: "全天候スマート運営",
        omP2: "管理サービス",
        omP3: "ユーザーに包括的な",
        omP4: "カスタマイズ運営サービスプランを提供",
        omP5: "高度に清掃されたアレイ汚れ、",
        omP6: "発電効率を大幅に向上させ、",
        omP7: "ユーザーに継続的な価値を創造",
        omP8: "太陽光アレイの全境自動清掃、",
        omP9: "スマートなスケジューリング、自動展開、",
        omP10: "効率的な作業、コスト削減",
        omP11: "独自の知的財産権、",
        omP12: "自社特許技術",
        omP13: "約120件以上",
        casesH1: "プロジェクト事例",
        casesP1: "お客様に安全で信頼性のある、技術的に優れた付加価値サービスとソリューションを提供",
        casesBtn: "さらに事例を見る",
        productsH1: "製品サービス",
        productsH2: "太陽光発電所用スマート清掃ロボット",
        productsH3: "レール清掃ロボット/乾湿清掃ロボット/リモコン清掃ロボット/手持ち清掃ツール",
        more: "詳細を見る"
	},
	about: {
		mainT: "Radiant",
		mainP1: "太陽光発電所向けワンストップ・オールウェザー型スマートO&Mサービスプロバイダー",
		profileH1: "ABOUT RADIANT",
		profileH2: "会社概要",
		profileS1: "Radiantグループは、中国蘇州に拠点を置く、太陽光発電所のスマートO&M、太陽光発電、太陽光発電応用製品、太陽光発電付属品、太陽光発電機器、金属製造、精密加工を統合した企業グループです。誠実さ、革新、仕事の楽しさ、平和、双方にとっての利益をコアバリューとし、業界をリードする技術力に基づき、お客様に最も価値のある製品と技術サポートサービスを提供しています。",
		profileS2: "現在、70名以上の専任技術者を擁し、そのうち研究開発者は20名、上級エンジニアは2名、博士/修士は1名、外部協力の博士人材は5名です。国家級ハイテク企業の称号を授与され、「江蘇省全天候型スマートO&Mロボットシステムエンジニアリング技術研究センター」を設立しています。",
		profileS3: "当社は、先進的な太陽光発電所スマートO&Mクラウドプラットフォームを有しており、ビッグデータセンターを通じて太陽光発電所とスマートロボットを一元管理、制御、スケジューリングし、発電所現場の省人化、無人化を実現するスマートO&M環境を実現しています。",
		profileS4: "Radiantは、お客様のニーズを第一に考え、お客様の価値観を満たすことを使命とし、安全で信頼性の高い、技術的に優れた付加価値サービスとソリューションをお客様に提供しています。品質と効率を重視した発展、イノベーション主導型の発展を断固として実行し、伝統産業の改革と発展、ハイテク産業の精力的な発展、そして変革と発展の加速を図ります。職人精神を発揮し、中国の産業復興の道のりで、Radiantは太陽光発電所のワンストップ・オールウェザー型スマートO&Mサービスプロバイダーになるべく、勇敢に前進していきます。",
		historyH1: "COMPANY HISTORY",
		historyH2: "会社の歴史",
		historyC1: "蘇州創捷自動化儀表実業有限公司を設立",
		historyC2: "蘇州瑞得恩光能科技有限公司を設立し、太陽光発電市場に参入",
		historyC3: "蘇州瑞得恩光能科技有限公司の支店、Radiant Japanを設立",
		historyC4: "日本で初めてPV System EXPOに出展",
		historyC5: "Radiant Japan株式会社を設立",
		historyC6: "蘇州瑞得恩光能科技有限公司の支店、Radiant Australiaを設立",
		historyC7: "モジュール洗浄機の独自開発に投資開始",
		historyC8: "Radiantモジュール洗浄ロボットが日本で初めてPV SYSTEM EXPOに登場",
		historyC9: "Radiant太陽光発電所スマート管理システムの構築に着手",
		historyC10: "SNEC第16回(2022年)国際太陽光発電・スマートエネルギー(上海)会議・展示会に参加",
		historyC11: "ベトナム太陽光発電・蓄電池展 The Solar Showに参加",
		historyC12: "日本国際太陽光発電展丨東京太陽光発電展に参加",
		historyC13: "中東エネルギー展 | サウジアラビアエネルギー展に参加",
		historyC14: "大阪太陽光発電展 PV EXPO OSAKAに参加",
		historyC15: "ドイツ・ミュンヘン太陽光発電展 Intersolar Europe 2024に参加",
		honorH1: "HONOR",
		honorH2: "荣誉资质"
	},
	news: {
        mainS1: "会社ニュース",
        more: "詳細を見る",
        play: "動画再生",
        slidersV1: "瑞得恩のイノベーション精神の継承と推進、デジタル化の推進",
        slidersV2: "日本国際太陽光発電展｜東京展示会"
	},
	products: {
		"mainH1": "製品センター",
		"mainP1": "スマートレール清掃ロボット/スマートドライ&ウェット清掃ロボット/スマートシャトル/ハンドヘルド清掃ツール",
		"advantage": "製品の利点",
		"rdn71": "スマートレール清掃ロボット",
		"rdn71b11": "自給電,全天候運用",
		"rdn71b12": "分離型モジュール設計",
		"rdn71b13": "露出設計,埃が溜まらない",
		"rdn71b14": "強力な清掃力",
		"rdn71b15": "大容量バッテリー",
		"rdn71b16": "スマート認識技術",
		"rdn71b21": "自身のソーラーパネルでデバイスを充電し、24時間電力を供給。",
		"rdn71b22": "上下部分が独立したモジュールで、分離型の駆動とリミット設計で、輸送と設置が容易。",
		"rdn71b23": "露出設計で埃が機械内部に溜まらないようにし、機械構造の安定性を保ちます。",
		"rdn71b24": "普通の清掃より8%効率が良く、最大で26%の発電量増加を実現。",
		"rdn71b25": "33AHの大容量耐高低温バッテリーで、最大2キロの航続距離。",
		"rdn71b26": "スマート認識パネル、橋架、基地局を自動認識し、自律的に位置を特定して自動補正。",
		"rdn22": "リモートコントロール清掃ロボット",
		"rdn22b11": "パネル清掃効率が高い",
		"rdn22b12": "パネル清掃度が高い",
		"rdn22b13": "軽量でパネルに無害",
		"rdn22b14": "モジュール設計",
		"rdn22b15": "",
		"rdn22b16": "",
		"rdn22b21": "2人で操作、1台のロボットが1日で最大1.5MWの清掃が可能。",
		"rdn22b22": "大量の水を使って洗浄し、パネルの清掃度は100%。",
		"rdn22b23": "重量最適化と軽量設計により、重量を34kgに制限し、長期間の運転でパネルに損傷なし。",
		"rdn22b24": "モジュール設計で、機体、清掃ヘッド、バッテリーが簡単に取り外し可能で、輸送とメンテナンスが容易。",
		"rdn22b25": "",
		"rdn22b26": "",
		"rdn12": "スマート清掃ロボット",
		"rdn12b11": "アレイ清掃経路計画",
		"rdn12b12": "スマート防滑、防落技術",
		"rdn12b13": "高い清掃率",
		"rdn12b14": "軽量でパネルに無害",
		"rdn12b15": "モジュール設計",
		"rdn12b16": "ナイトビジョン機能",
		"rdn12b21": "ロボットが自動的にアレイの種類とコンポーネントのサイズを認識し、最適な清掃経路を自動的に計画。",
		"rdn12b22": "構造、センサー、スマートコントロールの連携設計により、滑りやすい斜面やアレイの縁でも安定して歩行。",
		"rdn12b23": "スマート浮動清掃技術と経路最適化設計により、コンポーネントパネルの表面の埃を分層して清掃し、清掃率は98%以上。",
		"rdn12b24": "28kg以下で無害",
		"rdn12b25": "簡単な分解組立",
		"rdn12b26": "ナイトビジョン機能で夜間作業効率化",
		"rdn21": "スマート清掃ロボット",
		"rdn21b11": "環境識別",
		"rdn21b12": "自動経路計画",
		"rdn21b13": "防滑落技術",
		"rdn21b14": "軽量でパネルに無害",
		"rdn21b15": "乾掃除",
		"rdn21b16": "モジュール設計",
		"rdn21b21": "Vslam技術で自動建図",
		"rdn21b22": "最適経路計画",
		"rdn21b23": "98%以上の清掃率",
		"rdn21b24": "36kg以下で無害",
		"rdn21b25": "乾掃除で水不要",
		"rdn21b26": "モジュール設計で簡単組立",
		"rdn51": "スマート観測所",
		"rdn51b11": "リアルタイムデータアップロード",
		"rdn51b12": "自給電独立電源",
		"rdn51b13": "スマートIV検査",
		"rdn51b14": "精密な気象データ分析",
		"rdn51b15": "",
		"rdn51b16": "",
		"rdn51b21": "無線通信でデータアップロード",
		"rdn51b22": "自給電で24時間監視",
		"rdn51b23": "スマートIVで発電監視",
		"rdn51b24": "気象データの精密分析",
		"rdn51b25": "",
		"rdn51b26": "",
		"rdn31": "ハンドヘルド掃除ツール",
		"rdn31b11": "安定した動作",
		"rdn31b12": "モジュール設計",
		"rdn31b13": "軽量設計",
		"rdn31b14": "水を使用した重汚染清掃",
		"rdn31b15": "脱困機能",
		"rdn31b16": "夜間機能",
		"rdn31b21": "隠れモーターデザイン",
		"rdn31b22": "簡単な分解組立",
		"rdn31b23": "8kgの軽量設計",
		"rdn31b24": "水を使用した重汚染清掃",
		"rdn31b25": "脱困ボタン機能",
		"rdn31b26": "夜間視覚機能"
	},
	om: {
		mainH1: "運用サービス",
        mainP1: "グリーンエネルギー 賢い運用",
        c1S1: "発電所の灰除去",
        c1P1: "スマートにパネル上のホコリ、鳥のフン、枯葉、頑固な汚れを除去。特にパネル下部の灰を清掃し、最適な発電状態を維持。",
        c2S1: "電気設備の点検",
        c2P1: "ドローン、EL検査、IV検査、サーモグラフィーでパネルを全方位点検。隠れた亀裂、ホットスポット、セル損傷などを早期発見し、発電効率を向上。",
        c2P11: "インバーター、集電箱などの電気設備を点検。故障リスクを排除し、安全運転を確保。",
        c2P12: "パネルの影やシステム構成の合理性を改善し、発電効率をアップ。",
        c1S2: "草刈りサービス",
        c1P2: "スマート機器を使って、春秋に雑草を除去。パネルの遮蔽を防ぎ、秋冬には枯草火災を防止。",
		c2S2: "除雪サービス",
        c2P2: "機器と人力で初雪時に迅速にパネルの雪を除去。雪の凍結を防ぎ、発電量の低下を防止。"
	},
	cases: {
		mainH1: "事例",
		mainP1: "高効率・低コスト",
		play: "再生ボタン",
		rdn71: "スマート軌道掃除ロボット",
		rdn71S1: "新疆光伏発電所掃除現場",
		rdn71S2: "寧夏光伏発電所掃除現場",
		rdn22: "リモコン掃除ロボット",
		rdn22S1: "瑞得恩屋上光伏発電所掃除現場",
		rdn22S2: "屋上光伏発電所掃除現場",
		rdn2112: "スマート掃除ロボット",
		rdn2112S1: "南通屋上光伏発電所掃除現場",
		rdn2112S2: "瑞得恩屋上光伏発電所掃除現場",
		rdn51: "スマート監視ステーション",
		rdn51S1: "新疆光伏発電所掃除現場",
		rdn31: "手持掃除工具",
		rdn31S1: "日本光伏発電所掃除現場",
		rdn31S2: "昆山屋上光伏発電所掃除現場"
	},
    contact: {
		mainT: "お問い合わせ",
		mainP1: "Radiant Group は太陽光発電所知能運営とメンテナンスサ",
		mainP2: "ービス、太陽光発電架台及び関連製品の開発製造，",
		mainP3: "金属加工と精密加工を中核とした総合産業グループです",
		companyCNH: "蘇州瑞得恩光能科技有限会社",
		companyCNAddr: "住所:中国蘇州市胥口鎭子胥路636号",
		companyCNTel1: "電話：",
		companyCNTel2: "86-512-66572916",
		companyCNFax1: "ファックス：",
		companyCNFax2: "86-512-66572756",
		companyCNEml1: "メールボックス：",
		companyCNWz1: "ウェブサイト：",
		companyCNWz2: "www.radiantpv.com",
		companyCNXs1: "販売ホットライン：",
		companyCNXs2: "0512-66368219",	
		companyCNXs3: "0512-66316900",
		companyCNGo: "ここに行く",
		companyCNF: "Radiant China",
		companyJAH: "Radiant Japan 株式会社",
		companyJAAddr: "住所：〒110-0003　東京都台東区根岸5－12－12　小沼ビル２F",
		companyJATel1: "電話：",
		companyJATel2: "(03)6427-7173",
		companyJAFax1: "ファックス：",
		companyJAFax2: "(03)6427-7183",
		companyJAEml1: "メールボックス：",
		companyJAWz1: "ウェブサイト：",
		companyJAWz2: "www.radiantpv.com",
		companyJAXs1: "販売ホットライン：",
		companyJAXs2: "0512-66368219",	
		companyJAXs3: "0512-66316900",
		companyJAGo: "ここに行く",
		companyJAF: "Radiant Japan",
		companyAUH: "Radiant Australia 株式会社",
		companyAUAddr: "住所:43 Porter Street, Hemmant QLD 4174.",
		companyAUTel1: "電話：",
		companyAUTel2: "07 3823 2096",
		companyAUFax1: "ファックス：",
		companyAUFax2: "86-512-66572756",
		companyAUEml1: "メールボックス：",
		companyAUWz1: "ウェブサイト：",
		companyAUWz2: "www.radiantpv.com",
		companyAUXs1: "販売ホットライン：",
		companyAUXs2: "0512-66368219",	
		companyAUXs3: "0512-66316900",
		companyAUGo: "ここに行く",
		companyAUF: "Radiant Australia"
    }
}